import React from 'react';

import { TaskNanoID } from '../../../../../../../../tasks/tasksTypes';

import {
  FETCH_SHOW_TASK_DASHBOARD_QUERY,
  FetchShowTaskDashboardQueryResponse
} from '../../../../../../../../tasks/queries/fetchShowTaskDashboard.query';

import { useTask } from '../../../../../../../../tasks/hooks/useTask';

import { DashboardActiveTaskMessageContent } from './components/DashboardActiveTaskMessageContent';

import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';

import { TaskCache } from '../../../../../../../../tasks/TaskCache';

interface DashboardActiveTaskMessagesProps {
  taskNanoId?: TaskNanoID;
}

function DashboardActiveTaskMessages({
  taskNanoId
}: DashboardActiveTaskMessagesProps) {
  const { task, taskError, taskFetched, taskIsPlaceholderData } =
    useTask<FetchShowTaskDashboardQueryResponse>({
      tasks: [],
      cacheKey: TaskCache.showDashboardCacheKey(),
      query: FETCH_SHOW_TASK_DASHBOARD_QUERY,
      uuid: taskNanoId,
      options: {
        enabled: !!taskNanoId,
        enabledPlaceholder: !!taskNanoId
      }
    });

  return (
    <div className="h-full flex-1 border-l-4 border-double dark:border-gray-800">
      <div className="h-full flex flex-col-reverse">
        <AlertMessage addClassName="mt-4" message={taskError} />
        <LoadingSkeleton
          loaded={!taskNanoId || taskFetched || taskIsPlaceholderData}
        >
          <DashboardActiveTaskMessageContent task={task} />
        </LoadingSkeleton>
      </div>
    </div>
  );
}

export default DashboardActiveTaskMessages;
