import {
  BooleanFilter,
  Currencies,
  DateFilterType,
  DateType,
  FetchItemFetched,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  FloatFilterType,
  ID,
  IdFilterType,
  IntegerFilterType,
  MoneyType,
  NanoIdFilterType,
  TextFilterType
} from '../../types';
import { InvoiceStatus, InvoiceStatusFilter } from '../invoices/invoicesTypes';
import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  CheckedPartial,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import { ItemCreatedAt, ItemID, ItemName } from '../items/itemsTypes';
import {
  ProjectCreatedAt,
  ProjectName,
  ProjectNanoID
} from '../projects/projectsTypes';
import {
  TaskCreatedAt,
  TaskName,
  TaskNanoID,
  TaskStatusFilter
} from '../tasks/tasksTypes';
import {
  SmartContractStatus,
  SmartContractStatusFilterType
} from '../smartContracts/smartContractsTypes';
import { FetchGroupedItemsQueryResponse } from './queries/fetchGroupedItems.query';
import { MoveTaskItemsInputDataType } from '../tasks/hooks/useMoveTaskItems/useMoveTaskItems';
import { UpdateIndexQueryItemsCacheAction } from '../common/hooks/base/reactQuery/useIndexQuery';
import { ItemCategoryName } from '../itemCategories/itemCategoriesTypes';
import { ItemTypeName } from '../itemTypes/itemTypesTypes';

export type GroupedItemItemID = ItemID;
export type GroupedItemItemName = ItemName;
export type GroupedItemItemCategoryName = ItemCategoryName;
export type GroupedItemItemCreatedAt = ItemCreatedAt;
export type GroupedItemBindingStatus = GroupedItemBindingStatuses;
export type GroupedItemCreationMonth = DateType;
export type GroupedItemMonthTotal = MoneyType;
export type GroupedItemProjectName = ProjectName;
export type GroupedItemProjectNanoID = ProjectNanoID;
export type GroupedItemProjectTotal = MoneyType;
export type GroupedItemQuantity = number;
export type GroupedItemInvoiceStatus = InvoiceStatus;
export type GroupedItemSmartcontractStatus = SmartContractStatus;
export type GroupedItemStatusChangedAt = DateType;
export type GroupedItemTaskName = TaskName;
export type GroupedItemTaskNanoID = TaskNanoID;
export type GroupedItemTaskTotal = MoneyType;
export type GroupedItemTotalPrice = MoneyType;
export type GroupedItemProjectCreatedAt = ProjectCreatedAt;
export type GroupedItemRefunded = boolean;
export type GroupedItemTaskCreatedAt = TaskCreatedAt;
export type GroupedItemItemsIds = ItemID[];
export type GroupedItemBurning = boolean;
export type GroupedItemShowMoveItemButton = boolean;
export type GroupedItemShowVoidButton = boolean;
export type GroupedItemConvertedPrice = MoneyType;
export type GroupedItemConvertedPricePerItem = MoneyType;
export type GroupedItemConvertedporjectTotal = MoneyType;
export type GroupedItemConvertedTaskTotal = MoneyType;
export type GroupedItemConvertedMonthTotal = MoneyType;
export type GroupedItemViewPrice = MoneyType;
export type GroupedItemViewPriceCurrency = Currencies;
export type GroupedItemItemConvertedPrice = MoneyType;
export type GroupedItemItemItemTypeName = ItemTypeName;
export type GroupedItemItemItemCategoryName = ItemCategoryName;
export type GroupedItemItemShowVoidButton = boolean;
export type GroupedItemItemUnbindedAt = DateType;
export type GroupedItemItemTagName = string;
export type GroupedItemItemTagID = ID;

export type GroupedItemSplitConvertedPrice = MoneyType;
export type GroupedItemSplitLabel = string;
export type GroupedItemSplitPartNumber = number;
export type GroupedItemSplitPartPercent = number;

export const enum GroupedItemBindingStatuses {
  BINDED = 'binded',
  MIXED = 'mixed',
  UNBINDED = 'unbinded'
}

export const enum FetchGroupedItemsSortTypes {
  BINDING_STATUS_ASC = 'BINDING_STATUS_ASC',
  BINDING_STATUS_DESC = 'BINDING_STATUS_DESC',
  CREATION_MONTH_ASC = 'CREATION_MONTH_ASC',
  CREATION_MONTH_DESC = 'CREATION_MONTH_DESC',
  INVOICE_STATUS_ASC = 'INVOICE_STATUS_ASC',
  INVOICE_STATUS_DESC = 'INVOICE_STATUS_DESC',
  ITEM_CREATED_AT_ASC = 'ITEM_CREATED_AT_ASC',
  ITEM_CREATED_AT_DESC = 'ITEM_CREATED_AT_DESC',
  ITEM_ID_ASC = 'ITEM_ID_ASC',
  ITEM_ID_DESC = 'ITEM_ID_DESC',
  ITEM_NAME_ASC = 'ITEM_NAME_ASC',
  ITEM_NAME_DESC = 'ITEM_NAME_DESC',
  ITEM_PRICE_ASC = 'ITEM_PRICE_ASC',
  ITEM_PRICE_DESC = 'ITEM_PRICE_DESC',
  PROJECT_CREATED_AT_ASC = 'PROJECT_CREATED_AT_ASC',
  PROJECT_CREATED_AT_DESC = 'PROJECT_CREATED_AT_DESC',
  PROJECT_NAME_ASC = 'PROJECT_NAME_ASC',
  PROJECT_NAME_DESC = 'PROJECT_NAME_DESC',
  QUANTITY_ASC = 'QUANTITY_ASC',
  QUANTITY_DESC = 'QUANTITY_DESC',
  REFUNDED_ASC = 'REFUNDED_ASC',
  REFUNDED_DESC = 'REFUNDED_DESC',
  SMART_CONTRACT_STATUS_ASC = 'SMART_CONTRACT_STATUS_ASC',
  SMART_CONTRACT_STATUS_DESC = 'SMART_CONTRACT_STATUS_DESC',
  STATUS_CHANGED_AT_ASC = 'STATUS_CHANGED_AT_ASC',
  STATUS_CHANGED_AT_DESC = 'STATUS_CHANGED_AT_DESC',
  TASK_CREATED_AT_ASC = 'TASK_CREATED_AT_ASC',
  TASK_CREATED_AT_DESC = 'TASK_CREATED_AT_DESC',
  TASK_NAME_ASC = 'TASK_NAME_ASC',
  TASK_NAME_DESC = 'TASK_NAME_DESC',
  TASK_TOTAL_ASC = 'TASK_TOTAL_ASC',
  TASK_TOTAL_DESC = 'TASK_TOTAL_DESC',
  TOTAL_PRICE_ASC = 'TOTAL_PRICE_ASC',
  TOTAL_PRICE_DESC = 'TOTAL_PRICE_DESC',
  CONVERTED_PRICE_ASC = 'CONVERTED_PRICE_ASC',
  CONVERTED_PRICE_DESC = 'CONVERTED_PRICE_DESC',
  CONVERTED_PRICE_PER_ITEM_ASC = 'CONVERTED_PRICE_PER_ITEM_ASC',
  CONVERTED_PRICE_PER_ITEM_DESC = 'CONVERTED_PRICE_PER_ITEM_DESC',
  TAG_NAME_ASC = 'TAG_NAME_ASC',
  TAG_NAME_DESC = 'TAG_NAME_DESC',
  TEMP_INVOICE_NAME_ASC = 'TEMP_INVOICE_NAME_ASC',
  TEMP_INVOICE_NAME_DESC = 'TEMP_INVOICE_NAME_DESC'
}

export const enum FetchGroupedItemsSortableFields {
  BINDING_STATUS = 'bindingStatus',
  CREATION_MONTH = 'creationMonth',
  INVOICE_STATUS = 'invoiceStatus',
  ITEM_CREATED_AT = 'itemCreatedAt',
  ITEM_ID = 'itemId',
  ITEM_NAME = 'itemName',
  ITEM_PRICE = 'itemPrice',
  PROJECT_NAME = 'projectName',
  QUANTITY = 'quantity',
  REFUNDED = 'refunded',
  SMART_CONTRACT_STATUS = 'smartContractsStatus',
  STATUS_CHANGED_AT = 'statusChangedAt',
  TASK_CREATED_AT = 'taskCreatedAt',
  PROJECT_CREATED_AT = 'projectCreatedAt',
  TASK_NAME = 'taskName',
  TASK_TOTAL = 'taskTotal',
  TOTAL_PRICE = 'totalPrice',
  TAG_NAME = 'tagName',
  TEMP_INVOICE_NAME = 'tempInvoiceName',
  CONVERTED_PRICE = 'convertedPrice',
  CONVERTED_PRICE_PER_ITEM = 'convertedPricePerItem'
}

export interface FetchGroupedItemsFiltersType {
  companyNanoId?: NanoIdFilterType;
  currentOnly?: BooleanFilter;
  convertedPrice?: FloatFilterType;
  convertedPricePerItem?: FloatFilterType;
  id?: IdFilterType;
  invoiceStatus?: InvoiceStatusFilter;
  invoiceStatusChangedAt?: DateFilterType;
  itemCreatedAt?: DateFilterType;
  priceCents?: IntegerFilterType;
  projectNanoId?: NanoIdFilterType;
  projectCreatedAt?: DateFilterType;
  taskNanoId?: NanoIdFilterType;
  smartContractStatus?: SmartContractStatusFilterType;
  taskCreatedAt?: DateFilterType;
  taskStatus?: TaskStatusFilter;
  withoutParentInvoice?: BooleanFilter;
  totalPrice?: IntegerFilterType;
  itemName?: TextFilterType;
  projectName?: TextFilterType;
  taskName?: TextFilterType;
  generalLedgerId?: IdFilterType;
  quantity?: IntegerFilterType;
  tempInvoiceId?: IdFilterType;
  bindingStatus?: TextFilterType;
  itemTagId?: IdFilterType;
}

export const enum FetchGroupedItemsFilterFields {
  COMPANY_NANO_ID = 'companyNanoId',
  CONVERTED_PRICE = 'convertedPrice',
  CONVERTED_PRICE_PER_ITEM = 'convertedPricePerItem',
  ID = 'id',
  INVOICE_STATUS = 'invoiceStatus',
  INVOICE_STATUS_CHANGED_AT = 'invoiceStatusChangedAt',
  ITEM_CREATED_AT = 'itemCreatedAt',
  PRICE_CENTS = 'priceCents',
  PROJECT_NANO_ID = 'projectNanoId',
  PROJECT_CREATED_AT = 'projectCreatedAt',
  SMART_CONTRACT_STATUS = 'smartContractStatus',
  TASK_CREATED_AT = 'taskCreatedAt',
  TASK_STATUS = 'taskStatus',
  WITHOUT_PARENT_INVOICE = 'withoutParentInvoice',
  TOTAL_PRICE = 'totalPrice',
  ITEM_NAME = 'itemName',
  PROJECT_NAME = 'projectName',
  TASK_NAME = 'taskName',
  QUANTITY = 'quantity',
  TEMP_INVOICE_ID = 'tempInvoiceId',
  BINDING_STATUS = 'bindingStatus',
  ITEM_TAG_ID = 'itemTagId'
}

export const enum GroupedItemsGroupBy {
  ITEM = 'item',
  ITEM_TAGS = 'item_tags',
  PROJECT = 'project',
  TASK = 'task',
  DATE = 'date',
  SPLIT = 'split',
  INVOICE_CREATION_MONTH = 'invoice_creation_month',
  TASK_DUE_MONTH = 'task_due_month'
}

export type FetchGroupedItemsSortType = FetchItemsSort;
export type FetchGroupedItemsSortItemsType = FetchItemsSortItems;
export type FetchGroupedItemsPageType = FetchItemsPage;
export type FetchGroupedItemsLimitType = FetchItemsLimit;
export type FetchGroupedItemsErrorMessageType = FetchItemsErrorMessage;
export type FetchGroupedItemsFetchedType = FetchItemsFetched;
export type FetchGroupedItemFetchedType = FetchItemFetched;
export type FetchGroupedItemsIsPlaceholderDataType =
  FetchItemsIsPlaceholderData;

export type FetchGroupedItemsClearGroupedItemsFilters =
  FetchItemsClearItemsFilters;
export type FetchGroupedItemsPaginateItemsType = FetchItemsPaginateItems;
export type FetchGroupedItemsPrefetchItemsType = FetchItemsPrefetchItems;
export type FetchGroupedItemsTotalCountType = FetchItemsTotalCount;
export type FetchGroupedItemsFilterItemsType =
  FetchItemsFilterItems<FetchGroupedItemsFiltersType>;

export type ChangeItemsFiltersFunc = (
  changedFilters: FetchGroupedItemsFiltersType,
  removeFilters?: string[]
) => void;

export type FetchGroupedItemsGqlQuery = FetchItemsGqlQuery;
export type FetchGroupedItemsCacheKeyType = FetchItemsCacheKey;

export type FetchGroupedItemsChecked = Checked;
export type FetchGroupedItemsCheckedHash = CheckedHashItem;
export type FetchGroupedItemsOnSetCheckedIds = OnSetCheckedIds;
export type FetchGroupedItemsCheckedAll = CheckedAll;
export type FetchGroupedItemsCheckedPartial = CheckedPartial;
export type FetchGroupedItemsOnCheckAll = OnCheckAll;

export type UpdateGroupedItemsCacheAction = UpdateIndexQueryItemsCacheAction<
  FetchGroupedItemsQueryResponse,
  MoveTaskItemsInputDataType
>;
