import React, { useCallback, useMemo } from 'react';

import some from 'lodash/some';

import {
  SmartContractID,
  UpdateSmartContractsCacheKeys
} from '../../../../smartContracts/smartContractsTypes';
import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { TaskNanoID } from '../../../tasksTypes';

import {
  ACCEPT_INVITE_TO_TASK_QUERY,
  AcceptInviteToTaskQueryResponse
} from '../../../queries/acceptInviteToTask.query';

import {
  FETCH_SMART_CONTRACT_SHARES_QUERY,
  FetchTaskSmartContractShareQueryResponse
} from '../../../../smartContractShares/queries/fetchSmartContractShares.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useAcceptInviteToTask } from '../../../hooks/useAcceptInviteToTask';
import { useFinPaginatedSmartContractShares } from '../../../../smartContractShares/hooks/useFinPaginatedSmartContractShares';

import { TaskCheckInToSmartContractShareButton } from '../TaskCheckInToSmartContractShareButton';

import { IconsEnum } from '../../../../../assets/icons/types';
import { SmartContractCache } from '../../../../smartContracts/SmartContractCache';
import { SmartContractShareCache } from '../../../../smartContractShares/SmartContractShareCache';
import { SmartContractShareStatuses } from '../../../../smartContractShares/smartContractSharesTypes';

import { tasksKeys } from '../../../../../locales/keys';

interface TaskFinCheckInButtonProps {
  taskNanoId: TaskNanoID;
  smartContractId?: SmartContractID;
  cacheKeys?: UpdateSmartContractsCacheKeys;
  disabled?: IsDisabled;
  className?: ClassName;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
}

function TaskFinCheckInButton({
  cacheKeys,
  taskNanoId,
  smartContractId,
  disabled,
  className,
  i18nText,
  i18nTextClassName,
  icon,
  iconClassName
}: TaskFinCheckInButtonProps) {
  const currentUser = useCurrentUser();

  const {
    acceptInviteToTask,
    acceptInviteToTaskLoading,
    acceptInviteToTaskErrorMessage
  } = useAcceptInviteToTask<AcceptInviteToTaskQueryResponse>({
    query: ACCEPT_INVITE_TO_TASK_QUERY,
    cacheKeys
  });

  const {
    smartContractShares,
    smartContractSharesFetched,
    smartContractSharesIsPlaceholderData,
    smartContractSharesErrorMessage
  } = useFinPaginatedSmartContractShares<FetchTaskSmartContractShareQueryResponse>(
    {
      query: FETCH_SMART_CONTRACT_SHARES_QUERY,
      cacheKey:
        SmartContractShareCache.smartContractSmartContractShareCacheKey(
          smartContractId
        ),
      initialLimit: 1000,
      initialFilters: {
        smartContractId: { eq: smartContractId }
      },
      options: {
        withoutPrefetch: true,
        enabled: !!smartContractId,
        enabledPlaceholder: !!smartContractId
      }
    }
  );

  const handleAfterCheckIn = useCallback<() => void>(() => {
    acceptInviteToTask({ uuid: taskNanoId });
  }, [acceptInviteToTask, taskNanoId]);

  const userInSelectedSmartContract = useMemo<boolean>(
    () =>
      some(
        smartContractShares,
        (smartContractShare) =>
          smartContractShare.generalLedger?.company?.owner?.nanoId ===
            currentUser.nanoId &&
          smartContractShare.status === SmartContractShareStatuses.CHECKED_IN
      ),

    [currentUser.nanoId, smartContractShares]
  );

  const loadedSmartContractShares = useMemo<boolean>(
    () =>
      smartContractId
        ? smartContractSharesFetched || smartContractSharesIsPlaceholderData
        : true,
    [
      smartContractId,
      smartContractSharesFetched,
      smartContractSharesIsPlaceholderData
    ]
  );

  const smartContractsCacheKeys = useMemo(
    () => [
      SmartContractCache.taskSmartContractsCacheKey(taskNanoId),
      SmartContractShareCache.smartContractSmartContractShareCacheKey(
        smartContractId
      )
    ],
    [smartContractId, taskNanoId]
  );

  useShowToastOnErrorChange({
    error: smartContractSharesErrorMessage || acceptInviteToTaskErrorMessage
  });

  if (!loadedSmartContractShares || userInSelectedSmartContract) {
    return null;
  }

  return (
    <TaskCheckInToSmartContractShareButton
      smartContractId={smartContractId}
      cacheKeys={smartContractsCacheKeys}
      disabled={disabled || acceptInviteToTaskLoading}
      className={className}
      i18nTextClassName={i18nTextClassName}
      i18nText={i18nText}
      tooltipI18nText={tasksKeys.buttons.checkIn}
      icon={icon}
      iconClassName={iconClassName}
      afterCheckIn={handleAfterCheckIn}
    />
  );
}

export default TaskFinCheckInButton;
